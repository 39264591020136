/**
 * Common example scss file
 */

// global styling
html,
body {
    height   : 100%;
    width    : 100%;
    overflow : hidden;
}

body {
    margin      : 0;
    padding     : 0;
    font-size   : 14px;
    font-family : Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    display     : flex;
    flex-flow   : column nowrap;
    align-items : stretch;
}

#container, #app, #root {
    position       : relative;
    flex           : 1 1 100%;
    min-height     : 0;
    display        : flex;
    flex-direction : column;
    align-items    : stretch;
    transform      : translate(0, 0);
    top            : 0;
    left           : 0;
}

.b-react-scheduler-container,
.b-vue-scheduler-container,
.b-angular-scheduler-container {
    flex : 1;
}
