/**
 * Toolbar sass file
 */
.demo-toolbar {
    border-bottom: 1px solid #d8d9da;
    background-color: #f3f4f5;
    color: #4f5964;

    display: flex;
    padding: 0.5em;

    align-items: center;

    .spacer {
        flex: 1;
    }

    &.align-right {
        justify-content: flex-end;
    }

    &.align-justify {
        justify-content: space-between;
    }

    > *:not(:first-child) {
        margin-left: 0.6em;
    }
}

.b-theme-stockholm .demo-toolbar {
    background-color: #f3f4f5;
    color: #4f5964;
    border-bottom: 1px solid #d8d9da;
}

.b-theme-classic .demo-toolbar {
    background-color: #75757f;
    color: #e6e6e6;
    border-bottom: 1px solid #2b2b2f;
}

.b-theme-classic-light .demo-toolbar {
    background-color: #f9f9f9;
    color: #616161;
    border-bottom: 1px solid #e0e0e0;
}

.b-theme-classic-dark .demo-toolbar {
    background-color: #2b2b2f;
    color: #c0c1c2;
    border-bottom: 1px solid #2b2b2f;
}

.b-theme-classic-material .demo-toolbar {
    background-color: #fff;
    color: #616161;
    border-bottom: none;
}
